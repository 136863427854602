import { ReactComponent as StopCircleGray } from '@src/assets/icons//stop-circle-gray.svg';
import { ReactComponent as AboutUsIcon } from '@src/assets/icons/about-us.svg';
import { ReactComponent as ActivityIcon } from '@src/assets/icons/activity.svg';
import { ReactComponent as AdditionalDetailIcon } from '@src/assets/icons/additional-detail.svg';
import { ReactComponent as AddressFeatured } from '@src/assets/icons/address-featured.svg';
import { ReactComponent as Address } from '@src/assets/icons/address.svg';
import { ReactComponent as AlertCircleIcon } from '@src/assets/icons/alert-circle.svg';
import { ReactComponent as AlertModalIcon } from '@src/assets/icons/alert-modal.svg';
import { ReactComponent as AlertRedTriangle } from '@src/assets/icons/alert-red-triangle.svg';
import { ReactComponent as AlertTriangle } from '@src/assets/icons/alert-triangle.svg';
import { ReactComponent as AllowanceIcon } from '@src/assets/icons/allowance.svg';
import { ReactComponent as AppStoreIcon } from '@src/assets/icons/app-store.svg';
import { ReactComponent as ApplyNow } from '@src/assets/icons/apply-now.svg';
import { ReactComponent as ApprovedIcon } from '@src/assets/icons/approved-icon.svg';
import { ReactComponent as ArrowDownLeft } from '@src/assets/icons/arrow-down-left.svg';
import { ReactComponent as ArrowDown } from '@src/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowIcon } from '@src/assets/icons/arrow-icon.svg';
import { ReactComponent as RightArrowBlackIcon } from '@src/assets/icons/arrow-right-black.svg';
import { ReactComponent as ArrowRightCircleIcon } from '@src/assets/icons/arrow-right-circle.svg';
import { ReactComponent as ArrowRight } from '@src/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowSquare } from '@src/assets/icons/arrow-square.svg';
import { ReactComponent as ArrowUpRight } from '@src/assets/icons/arrow-up-right.svg';
import { ReactComponent as AtmUseIcon } from '@src/assets/icons/atm-use-icon.svg';
import { ReactComponent as AuLogo } from '@src/assets/icons/au-logo.svg';
import { ReactComponent as AURewardsLogo } from '@src/assets/icons/au-rewards.svg';
import { ReactComponent as AuroChatIcon } from '@src/assets/icons/auro-chat.svg';
import { ReactComponent as Back } from '@src/assets/icons/back.svg';
import { ReactComponent as Bag } from '@src/assets/icons/bag.svg';
import { ReactComponent as BankIconOrange } from '@src/assets/icons/bank-icon-orange.svg';
import { ReactComponent as BankLogoGray300 } from '@src/assets/icons/bank-logo-gray-300.svg';
import { ReactComponent as BankLogo } from '@src/assets/icons/bank-logo.svg';
import { ReactComponent as BankOrange } from '@src/assets/icons/bank-orange.svg';
import { ReactComponent as BankOutlineIcon } from '@src/assets/icons/bank-outline.svg';
import { ReactComponent as BankIcon } from '@src/assets/icons/bank.svg';
import { ReactComponent as BankImage } from '@src/assets/icons/bankimage.svg';
import { ReactComponent as BbpsAutopay } from '@src/assets/icons/bbps-autopay.svg';
import { ReactComponent as Bell } from '@src/assets/icons/bell.svg';
import { ReactComponent as BiometricFailed } from '@src/assets/icons/biometric-failed.svg';
import { ReactComponent as BiometricSuccess } from '@src/assets/icons/biometric-success.svg';
import { ReactComponent as BlankCheckbox } from '@src/assets/icons/blank-checkbox.svg';
import { ReactComponent as BlockedIcon } from '@src/assets/icons/blocked-icon.svg';
import { ReactComponent as BriefCase } from '@src/assets/icons/briefcase.svg';
import { ReactComponent as BuildingOutlined } from '@src/assets/icons/building-outlined.svg';
import { ReactComponent as Building } from '@src/assets/icons/building.svg';
import { ReactComponent as BuildingsIcon } from '@src/assets/icons/buildings-outlined.svg';
import { ReactComponent as BusinessAddress } from '@src/assets/icons/business-address.svg';
import { ReactComponent as BusinessLoan } from '@src/assets/icons/business-loan.svg';
import { ReactComponent as CalendarIcon } from '@src/assets/icons/calendar.svg';
import { ReactComponent as CallThinIcon } from '@src/assets/icons/call-thin.svg';
import { ReactComponent as Camera } from '@src/assets/icons/camera.svg';
import { ReactComponent as DebitCardIcon } from '@src/assets/icons/card-icon.svg';
import { ReactComponent as CardsService } from '@src/assets/icons/card-service.svg';
import { ReactComponent as CardIcon } from '@src/assets/icons/card.svg';
import { ReactComponent as CardlessIcon } from '@src/assets/icons/cardless-icon.svg';
import { ReactComponent as CheckCircleGreen } from '@src/assets/icons/check-circle-green.svg';
import { ReactComponent as CheckCircleOutline } from '@src/assets/icons/check-circle-outline.svg';
import { ReactComponent as CheckCircleIcon } from '@src/assets/icons/check-circle.svg';
import { ReactComponent as CheckGrayIcon } from '@src/assets/icons/check-gray-icon.svg';
import { ReactComponent as CheckRoundIcon } from '@src/assets/icons/check-icon.svg';
import { ReactComponent as CheckSquare } from '@src/assets/icons/check-square.svg';
import { ReactComponent as CheckIcon } from '@src/assets/icons/check.svg';
import { ReactComponent as ChequeServices } from '@src/assets/icons/cheque-services.svg';
import { ReactComponent as ChevronDownIcon } from '@src/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@src/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronNeutral } from '@src/assets/icons/chevron-neutral.svg';
import { ReactComponent as ChevronOrangeRight } from '@src/assets/icons/chevron-orange-right.svg';
import { ReactComponent as ChevronRightBlack } from '@src/assets/icons/chevron-right-black.svg';
import { ReactComponent as ChevronRightOrange } from '@src/assets/icons/chevron-right-orange.svg';
import { ReactComponent as ChevronRight } from '@src/assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUpArrow } from '@src/assets/icons/chevron-up-arrow.svg';
import { ReactComponent as ChevronUpIcon } from '@src/assets/icons/chevron-up.svg';
import { ReactComponent as ClockBold } from '@src/assets/icons/clock-bold.svg';
import { ReactComponent as ClockIconOutlined } from '@src/assets/icons/clock-icon-outlined.svg';
import { ReactComponent as ClockIcon } from '@src/assets/icons/clock-icon.svg';
import { ReactComponent as CloseAccountIcon } from '@src/assets/icons/close-account.svg';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { ReactComponent as CoinIcon } from '@src/assets/icons/coins.svg';
import { ReactComponent as Collection } from '@src/assets/icons/collection-store.svg';
import { ReactComponent as CommentIcon } from '@src/assets/icons/comment.svg';
import { ReactComponent as ConnectDevice } from '@src/assets/icons/connect-device.svg';
import { ReactComponent as ConnectionFailed } from '@src/assets/icons/connection-failed.svg';
import { ReactComponent as ContactBranch } from '@src/assets/icons/contact-branch.svg';
import { ReactComponent as ControlIcon } from '@src/assets/icons/control.svg';
import { ReactComponent as CopyOrange } from '@src/assets/icons/copy-orange.svg';
import { ReactComponent as CopyIcon } from '@src/assets/icons/copy.svg';
import { ReactComponent as CreditCard } from '@src/assets/icons/credit-card.svg';
import { ReactComponent as Cross } from '@src/assets/icons/cross.svg';
import { ReactComponent as CsvIcon } from '@src/assets/icons/csv.svg';
import { ReactComponent as Cup } from '@src/assets/icons/cup.svg';
import { ReactComponent as Customername } from '@src/assets/icons/customer-name.svg';
import { ReactComponent as DateFeaturedIcon } from '@src/assets/icons/date-featured-icon.svg';
import { ReactComponent as DateofBirth } from '@src/assets/icons/date-of-birth.svg';
import { ReactComponent as DeactivatePayee } from '@src/assets/icons/deactivate-payee.svg';
import { ReactComponent as DebitCard } from '@src/assets/icons/debitcard.svg';
import { ReactComponent as DeleteError } from '@src/assets/icons/delete-error.svg';
import { ReactComponent as DeleteOrangeIcon } from '@src/assets/icons/delete-orange.svg';
import { ReactComponent as Delete } from '@src/assets/icons/delete.svg';
import { ReactComponent as DepositAmountIcon } from '@src/assets/icons/deposit-amount.svg';
import { ReactComponent as DepositCard } from '@src/assets/icons/deposit-card.svg';
import { ReactComponent as DepositIncoming } from '@src/assets/icons/deposit-in.svg';
import { ReactComponent as DepositService } from '@src/assets/icons/deposit-service.svg';
import { ReactComponent as DesktopDevice } from '@src/assets/icons/desktop-device.svg';
import { ReactComponent as InterestPayoutTypeIcon } from '@src/assets/icons/discount-shape.svg';
import { ReactComponent as DocumentText } from '@src/assets/icons/document-text.svg';
import { ReactComponent as Document } from '@src/assets/icons/document.svg';
import { ReactComponent as Docs } from '@src/assets/icons/documents.svg';
import { ReactComponent as Docx } from '@src/assets/icons/docx.svg';
import { ReactComponent as DotGray } from '@src/assets/icons/dot-gray.svg';
import { ReactComponent as ViewPayeeDotIcon } from '@src/assets/icons/dot-icon.svg';
import { ReactComponent as DotPayeelist } from '@src/assets/icons/dot-payeelist.svg';
import { ReactComponent as DotWhite } from '@src/assets/icons/dot-white.svg';
import { ReactComponent as DotIcon } from '@src/assets/icons/dot.svg';
import { ReactComponent as DownloadCloudBlue } from '@src/assets/icons/download-cloud-blue.svg';
import { ReactComponent as DownloadCloudGray } from '@src/assets/icons/download-cloud-gray.svg';
import { ReactComponent as DownloadCloudOrange } from '@src/assets/icons/download-cloud-orange.svg';
import { ReactComponent as DownloadCloudWhite } from '@src/assets/icons/download-cloud-white.svg';
import { ReactComponent as DownloadGrayIcon } from '@src/assets/icons/download-icon-gray.svg';
import { ReactComponent as DownloadOrangeIcon } from '@src/assets/icons/download-icon.svg';
import { ReactComponent as DownloadInProgress } from '@src/assets/icons/download-in-progress.svg';
import { ReactComponent as DownloadStatementIcon } from '@src/assets/icons/download-statement.svg';
import { ReactComponent as DownloadIcon } from '@src/assets/icons/download.svg';
import { ReactComponent as DropDownTransparent } from '@src/assets/icons/dropdown-icon-transparent.svg';
import { ReactComponent as DropdownIcon } from '@src/assets/icons/dropdown-icon.svg';
import { ReactComponent as EditColor } from '@src/assets/icons/edit-color.svg';
import { ReactComponent as Edit } from '@src/assets/icons/edit.svg';
import { ReactComponent as EmailBold } from '@src/assets/icons/email-bold.svg';
import { ReactComponent as EmailOutline } from '@src/assets/icons/email-outline.svg';
import { ReactComponent as Email } from '@src/assets/icons/email.svg';
import { ReactComponent as HappyEmoji } from '@src/assets/icons/emoji-happy.svg';
import { ReactComponent as SadEmoji } from '@src/assets/icons/emoji-sad.svg';
import { ReactComponent as Entitle } from '@src/assets/icons/entitle-icon.svg';
import { ReactComponent as Error500 } from '@src/assets/icons/error-500.svg';
import { ReactComponent as ErrorModal } from '@src/assets/icons/error-modal.svg';
import { ReactComponent as Evolute } from '@src/assets/icons/evolute.svg';
import { ReactComponent as ExternalLink } from '@src/assets/icons/external-link.svg';
import { ReactComponent as EyeOffCasa } from '@src/assets/icons/eye-off-casa.svg';
import { ReactComponent as EyeOff } from '@src/assets/icons/eye-off.svg';
import { ReactComponent as EyeOpen } from '@src/assets/icons/eye-open.svg';
import { ReactComponent as EyeIcon } from '@src/assets/icons/eye.svg';
import { ReactComponent as FaceAuth } from '@src/assets/icons/face-auth.svg';
import { ReactComponent as FailedIcon } from '@src/assets/icons/failed-icon.svg';
import { ReactComponent as FaqIcon } from '@src/assets/icons/faq.svg';
import { ReactComponent as Fastag } from '@src/assets/icons/fastag.svg';
import { ReactComponent as FeaturedIcon } from '@src/assets/icons/featured-icon.svg';
import {
  ReactComponent as Featured,
  ReactComponent as FeaturedIconSVG,
} from '@src/assets/icons/featured.svg';
import { ReactComponent as DocCircle } from '@src/assets/icons/file-text-circle.svg';
import { ReactComponent as FileWithLineOutlined } from '@src/assets/icons/file-with-line-outline.svg';
import { ReactComponent as FilterOutlineIcon } from '@src/assets/icons/filter-icon.svg';
import { ReactComponent as FilterIcon } from '@src/assets/icons/filter.svg';
import { ReactComponent as FlagMark } from '@src/assets/icons/flag-mark.svg';
import { ReactComponent as FlagTriangle } from '@src/assets/icons/flag-triangle.svg';
import { ReactComponent as FlagIcon } from '@src/assets/icons/flag.svg';
import { ReactComponent as FlexibilityIcon } from '@src/assets/icons/flexibility.svg';
import { ReactComponent as Funnel } from '@src/assets/icons/funnel.svg';
import { ReactComponent as Gender } from '@src/assets/icons/gender.svg';
import { ReactComponent as GetStatementIcon } from '@src/assets/icons/get-statement-icon.svg';
import { ReactComponent as Graph } from '@src/assets/icons/graph.svg';
import { ReactComponent as GreenCircleTick } from '@src/assets/icons/green-circle-tick.svg';
import { ReactComponent as GreenClock } from '@src/assets/icons/green-clock.svg';
import { ReactComponent as GreenDot } from '@src/assets/icons/green-dot.svg';
import { ReactComponent as GreenSuccesIcon } from '@src/assets/icons/green-success-icon.svg';
import { ReactComponent as TickGreen } from '@src/assets/icons/green-tick.svg';
import { ReactComponent as GreenTriangleIcon } from '@src/assets/icons/green-triangle.svg';
import { ReactComponent as GreenUpArrow } from '@src/assets/icons/green-up-arrow.svg';
import { ReactComponent as GreyPlusIcon } from '@src/assets/icons/grey-plus.svg';
import { ReactComponent as HassleFreeIcon } from '@src/assets/icons/hassle-free.svg';
import { ReactComponent as HdfcIcon } from '@src/assets/icons/hdfc.svg';
import { ReactComponent as HeadPhones } from '@src/assets/icons/headphones.svg';
import { ReactComponent as HelpCircle } from '@src/assets/icons/help-circle.svg';
import { ReactComponent as HelpHeadIcon } from '@src/assets/icons/help-head.svg';
import { ReactComponent as HelpOrangeCircle } from '@src/assets/icons/help-orange-circle.svg';
import { ReactComponent as HelpIcon } from '@src/assets/icons/help.svg';
import { ReactComponent as Hamburger } from '@src/assets/icons/hemburger.svg';
import { ReactComponent as CaseHistoryIcon } from '@src/assets/icons/history.svg';
import { ReactComponent as HomeFeatured } from '@src/assets/icons/home-featured.svg';
import { ReactComponent as HomeOutline } from '@src/assets/icons/home-outline.svg';
import { ReactComponent as HomeIcon } from '@src/assets/icons/home.svg';
import { ReactComponent as House } from '@src/assets/icons/house.svg';
import { ReactComponent as HumbergerCloseIcon } from '@src/assets/icons/humberger-close-navbar.svg';
import { ReactComponent as IconLamp } from '@src/assets/icons/icon-lamp.svg';
import { ReactComponent as PlaceholderPdf } from '@src/assets/icons/image_thumbnail.svg';
import { ReactComponent as INFlagIcon } from '@src/assets/icons/in-flag.svg';
import { ReactComponent as InfoCircleOutlineIcon } from '@src/assets/icons/info-circle-outline.svg';
import { ReactComponent as InfoCircleTransparent } from '@src/assets/icons/info-circle-transparent.svg';
import { ReactComponent as InfoCircleIcon } from '@src/assets/icons/info-circle.svg';
import { ReactComponent as InfoOutlinedIcon } from '@src/assets/icons/info-icon-outlined.svg';
import { ReactComponent as InfoIcon } from '@src/assets/icons/info-icon.svg';
import { ReactComponent as Info } from '@src/assets/icons/info.svg';
import { ReactComponent as InterestWhiteIcon } from '@src/assets/icons/interest-white-icon.svg';
import { ReactComponent as InvalidImage } from '@src/assets/icons/invalid-case.svg';
import { ReactComponent as PercentageSquare } from '@src/assets/icons/invest-insure.svg';
import { ReactComponent as Investment } from '@src/assets/icons/investment-icon.svg';
import { ReactComponent as Language } from '@src/assets/icons/language.svg';
import { ReactComponent as LeftArrowOutlined } from '@src/assets/icons/left-arrow-outlined.svg';
import { ReactComponent as LeftArrowIcon } from '@src/assets/icons/left-arrow.svg';
import { ReactComponent as LimitIcon } from '@src/assets/icons/limit-icon.svg';
import { ReactComponent as LoadingIcon } from '@src/assets/icons/loading-icon.svg';
import { ReactComponent as LoanIcon } from '@src/assets/icons/loans.svg';
import { ReactComponent as LocationOrange } from '@src/assets/icons/location-orange.svg';
import { ReactComponent as LockOrange } from '@src/assets/icons/lock-icon.svg';
import { ReactComponent as LockIcon } from '@src/assets/icons/lock.svg';
import { ReactComponent as LockerIcon } from '@src/assets/icons/locker.svg';
import { ReactComponent as Logout } from '@src/assets/icons/logout.svg';
import { ReactComponent as MacDevice } from '@src/assets/icons/mac-os.svg';
import { ReactComponent as MailGray } from '@src/assets/icons/mail-gray.svg';
import { ReactComponent as MailNewIcon } from '@src/assets/icons/mail-new.svg';
import { ReactComponent as MailOrange } from '@src/assets/icons/mail-orange.svg';
import { ReactComponent as EmailThinIcon } from '@src/assets/icons/mail-thin.svg';
import { ReactComponent as MailIcon } from '@src/assets/icons/mail.svg';
import { ReactComponent as ManagePayeeService } from '@src/assets/icons/manage-payee-service.svg';
import { ReactComponent as MasterCard } from '@src/assets/icons/mastercard.svg';
import { ReactComponent as MaturityIcon } from '@src/assets/icons/maturity-icon.svg';
import { ReactComponent as MaximizeIcon } from '@src/assets/icons/maximize.svg';
import { ReactComponent as MessageEdit } from '@src/assets/icons/message-edit.svg';
import { ReactComponent as MessageSquareIcon } from '@src/assets/icons/message-square.svg';
import { ReactComponent as MinimizeIcon } from '@src/assets/icons/minimize.svg';
import { ReactComponent as MinusIcon } from '@src/assets/icons/minus.svg';
import { ReactComponent as MobileBold } from '@src/assets/icons/mobile-bold.svg';
import { ReactComponent as MobileDevice } from '@src/assets/icons/mobile-device.svg';
import { ReactComponent as Mobile } from '@src/assets/icons/mobile.svg';
import { ReactComponent as MoneyRemove } from '@src/assets/icons/money-remove.svg';
import { ReactComponent as MoneySack } from '@src/assets/icons/money-sack.svg';
import { ReactComponent as MoneySendIcon } from '@src/assets/icons/money-send.svg';
import { ReactComponent as MoneyTransferService } from '@src/assets/icons/money-transfer-service.svg';
import { ReactComponent as MoneyTransferSolid } from '@src/assets/icons/money-transfer-solid.svg';
import { ReactComponent as MoneyTransferIcon } from '@src/assets/icons/moneytransfericon.svg';
import { ReactComponent as Moon } from '@src/assets/icons/moon.svg';
import { ReactComponent as NoContact } from '@src/assets/icons/no-contact.svg';
import { ReactComponent as NoDebitCardIcon } from '@src/assets/icons/no-debit-card-icon.svg';
import { ReactComponent as NoPaymentIcon } from '@src/assets/icons/no-dues-icon.svg';
import { ReactComponent as NotificationIcon } from '@src/assets/icons/notification.svg';
import { ReactComponent as OfferOutline } from '@src/assets/icons/offer-outline.svg';
import { ReactComponent as OneTimeIcon } from '@src/assets/icons/oneTimeSettlement-icon.svg';
import { ReactComponent as OnlineUsageIcon } from '@src/assets/icons/online-usage-icon.svg';
import { ReactComponent as Operator } from '@src/assets/icons/operator.svg';
import { ReactComponent as OrangeDot } from '@src/assets/icons/orange-dot.svg';
import { ReactComponent as OtherAddress } from '@src/assets/icons/other-address.svg';
import { ReactComponent as Others } from '@src/assets/icons/others.svg';
import { ReactComponent as OthersFilled } from '@src/assets/icons/othersFilled.svg';
import { ReactComponent as OutlineAgriTermLoan } from '@src/assets/icons/outline-agri-term-loan.svg';
import { ReactComponent as OutlineAutoLoan } from '@src/assets/icons/outline-auto-loan.svg';
import { ReactComponent as OutlineBusinessloan } from '@src/assets/icons/outline-business-loan.svg';
import { ReactComponent as OutlineCalendarIcon } from '@src/assets/icons/outline-calendar.svg';
import { ReactComponent as OutlineCardEdit } from '@src/assets/icons/outline-card-edit.svg';
import { ReactComponent as OutlineCard } from '@src/assets/icons/outline-card.svg';
import { ReactComponent as OutlineCircleCheck } from '@src/assets/icons/outline-circle-check.svg';
import { ReactComponent as OutlineCreditCard } from '@src/assets/icons/outline-credit-card.svg';
import { ReactComponent as OutlineCup } from '@src/assets/icons/outline-cup.svg';
import { ReactComponent as OutlineGift } from '@src/assets/icons/outline-gift.svg';
import { ReactComponent as OutlineGoldLoan } from '@src/assets/icons/outline-gold-loan.svg';
import { ReactComponent as OutlineHomeLoan } from '@src/assets/icons/outline-home-loan.svg';
import { ReactComponent as OutlineLock } from '@src/assets/icons/outline-lock.svg';
import { ReactComponent as OutlineMessageQuestion } from '@src/assets/icons/outline-message-question.svg';
import { ReactComponent as OutlinePasswordCheck } from '@src/assets/icons/outline-password-check.svg';
import { ReactComponent as OutlinePersonalLoan } from '@src/assets/icons/outline-personal-loan.svg';
import { ReactComponent as OutlineSetting4 } from '@src/assets/icons/outline-setting-4.svg';
import { ReactComponent as OutlineToggleOnCircle } from '@src/assets/icons/outline-toggle-on-circle.svg';
import { ReactComponent as OutlineTwoVehicleLoan } from '@src/assets/icons/outline-two-vehicle-loan.svg';
import { ReactComponent as OutlineUnblockedCard } from '@src/assets/icons/outline-unblocked-card.svg';
import { ReactComponent as OutlineWallet } from '@src/assets/icons/outline-wallet.svg';
import { ReactComponent as OutlineWarning } from '@src/assets/icons/outline-warning.svg';
import { ReactComponent as OutlinedArrowDownLeft } from '@src/assets/icons/outlined-arrow-down-left.svg';
import { ReactComponent as OutlinedPlusCircle } from '@src/assets/icons/outlined-plus-circle.svg';
import { ReactComponent as PackageIcon } from '@src/assets/icons/package-icon.svg';
import { ReactComponent as PasswordCheckFill } from '@src/assets/icons/password-check-fill.svg';
import { ReactComponent as PasswordCheck } from '@src/assets/icons/password-check.svg';
import { ReactComponent as Pause } from '@src/assets/icons/pause-icon.svg';
import { ReactComponent as PayArrowIcon } from '@src/assets/icons/pay-arrow-icon.svg';
import { ReactComponent as PayIcon } from '@src/assets/icons/pay-icon.svg';
import { ReactComponent as PaymentIcon } from '@src/assets/icons/payments.svg';
import { ReactComponent as PdfIcon } from '@src/assets/icons/pdf.svg';
import { ReactComponent as PenToolIcon } from '@src/assets/icons/pen-tool.svg';
import { ReactComponent as PencilDashIcon } from '@src/assets/icons/pencil-dash.svg';
import { ReactComponent as PencilIcon } from '@src/assets/icons/pencil.svg';
import { ReactComponent as PendingIcon } from '@src/assets/icons/pending-icon.svg';
import { ReactComponent as PendingInlineIcon } from '@src/assets/icons/pending-inline-icon.svg';
import { ReactComponent as PermanentAddress } from '@src/assets/icons/permanent-address.svg';
import { ReactComponent as PersonalCard } from '@src/assets/icons/personal-card-bold.svg';
import { ReactComponent as PersonalInfo } from '@src/assets/icons/personal-card.svg';
import { ReactComponent as PhoneNewIcon } from '@src/assets/icons/phone-new.svg';
import { ReactComponent as PhoneIcon } from '@src/assets/icons/phone.svg';
import { ReactComponent as PinOrange } from '@src/assets/icons/pin-orange.svg';
import { ReactComponent as PlaceHolderImg } from '@src/assets/icons/placeholder_img.svg';
import { ReactComponent as PlayStoreIcon } from '@src/assets/icons/play-store.svg';
import { ReactComponent as PlusIconOrange } from '@src/assets/icons/plus-icon.svg';
import { ReactComponent as PlusWhiteIcon } from '@src/assets/icons/plus-white-icon.svg';
import { ReactComponent as Plus } from '@src/assets/icons/plus.svg';
import { ReactComponent as PosUsageIcon } from '@src/assets/icons/pos-usage-icon.svg';
import { ReactComponent as PrivacyPolicyIcon } from '@src/assets/icons/privacy-policy.svg';
import { ReactComponent as Profile2UserOutline } from '@src/assets/icons/profile-2user-outline.svg';
import { ReactComponent as Profile2User } from '@src/assets/icons/profile-2user.svg';
import { ReactComponent as ProfileAdd } from '@src/assets/icons/profile-add.svg';
import { ReactComponent as PutFinger } from '@src/assets/icons/put-finger.svg';
import { ReactComponent as QRCode } from '@src/assets/icons/qr-code.svg';
import { ReactComponent as QrCodeIcon } from '@src/assets/icons/qrcode.svg';
import { ReactComponent as RealTimeIcon } from '@src/assets/icons/realTimeSettlement-icon.svg';
import { ReactComponent as RealTimeSettlement } from '@src/assets/icons/realtimesettlement.svg';
import { ReactComponent as ReceiptFillIcon } from '@src/assets/icons/receipt-fill-icon.svg';
import { ReactComponent as ReceiptIcon } from '@src/assets/icons/receipt.svg';
import { ReactComponent as RedAlertTriangle } from '@src/assets/icons/red-alert-triangle.svg';
import { ReactComponent as RedAttentionCircle } from '@src/assets/icons/red-attention-circle.svg';
import { ReactComponent as RedInfoIcon } from '@src/assets/icons/red-info.svg';
import { ReactComponent as RedTrash } from '@src/assets/icons/red-trash.svg';
import { ReactComponent as ReferIcon } from '@src/assets/icons/refer.svg';
import { ReactComponent as RefreshCwOrange } from '@src/assets/icons/refresh-cw-orange.svg';
import { ReactComponent as RefreshCw } from '@src/assets/icons/refresh-cw.svg';
import { ReactComponent as RefreshIconGray } from '@src/assets/icons/refresh-icon-gray.svg';
import { ReactComponent as RefreshIcon } from '@src/assets/icons/refresh-icon.svg';
import { ReactComponent as RefreshIconOutlined } from '@src/assets/icons/refresh-outlined.svg';
import { ReactComponent as RefreshPlaneIcon } from '@src/assets/icons/refresh-plane-icon.svg';
import { ReactComponent as RefreshCenterDot } from '@src/assets/icons/refresh-with-center-dot.svg';
import { ReactComponent as Refresh } from '@src/assets/icons/refresh.svg';
import { ReactComponent as RemoveAccount } from '@src/assets/icons/removeaccount.svg';
import { ReactComponent as RepeatIcon } from '@src/assets/icons/repeat-icon.svg';
import { ReactComponent as Reply } from '@src/assets/icons/reply.svg';
import { ReactComponent as ReportsinProgress } from '@src/assets/icons/reports-in-progress.svg';
import { ReactComponent as RetryFeaturedIcon } from '@src/assets/icons/retry-featured-icon.svg';
import { ReactComponent as RetryIcon } from '@src/assets/icons/retry-icon.svg';
import { ReactComponent as ReviewDetailIcon } from '@src/assets/icons/review-detail.svg';
import { ReactComponent as ReworkIconNew } from '@src/assets/icons/rework-icon.svg';
import { ReactComponent as ReworkIcon } from '@src/assets/icons/rework.svg';
import { ReactComponent as RightArrowOutlined } from '@src/assets/icons/right-arrow-outlined.svg';
import { ReactComponent as RightArrow } from '@src/assets/icons/right-arrow.svg';
import { ReactComponent as RightUp } from '@src/assets/icons/right-up.svg';
import { ReactComponent as RotateBlue } from '@src/assets/icons/rotate-blue.svg';
import { ReactComponent as RotateLeftArrow } from '@src/assets/icons/rotate-left-arrow.svg';
import { ReactComponent as RupayCard } from '@src/assets/icons/rupay-card.svg';
import { ReactComponent as Rupees } from '@src/assets/icons/rupees.svg';
import { ReactComponent as SafetyLock } from '@src/assets/icons/safety-lock.svg';
import { ReactComponent as SavingAccount } from '@src/assets/icons/saving-account.svg';
import { ReactComponent as SbiIcon } from '@src/assets/icons/sbi.svg';
import { ReactComponent as ScanQrCode } from '@src/assets/icons/scan-barcode.svg';
import { ReactComponent as SCFIcon } from '@src/assets/icons/scf-icon.svg';
import { ReactComponent as MaintenanceIcon } from '@src/assets/icons/schedule-maintenance.svg';
import { ReactComponent as SearchStatusNew } from '@src/assets/icons/search-status-new.svg';
import { ReactComponent as SearchStatus } from '@src/assets/icons/search-status.svg';
import { ReactComponent as Search } from '@src/assets/icons/search.svg';
import { ReactComponent as SendIcon } from '@src/assets/icons/send-icon.svg';
import { ReactComponent as SendOrangeIcon } from '@src/assets/icons/send-orange-icon.svg';
import { ReactComponent as SendPlain } from '@src/assets/icons/send-plain-icon.svg';
import { ReactComponent as SendWhiteOutline } from '@src/assets/icons/send-white-outline.svg';
import { ReactComponent as ServiceIcon } from '@src/assets/icons/services.svg';
import { ReactComponent as Setting3 } from '@src/assets/icons/setting-3.svg';
import { ReactComponent as SettingCircle } from '@src/assets/icons/setting-circle.svg';
import { ReactComponent as SettingIcon } from '@src/assets/icons/setting-icon.svg';
import { ReactComponent as Settings } from '@src/assets/icons/settings.svg';
import { ReactComponent as SettlementIcon } from '@src/assets/icons/settlement-icon.svg';
import { ReactComponent as Settlement } from '@src/assets/icons/settlement-store.svg';
import { ReactComponent as ShareIcon } from '@src/assets/icons/share-icon.svg';
import { ReactComponent as ShieldTick } from '@src/assets/icons/shield-tick.svg';
import { ReactComponent as Slash } from '@src/assets/icons/slash.svg';
import { ReactComponent as SortBy } from '@src/assets/icons/sort-by-icon.svg';
import { ReactComponent as SoundBox } from '@src/assets/icons/soundbox.svg';
import { ReactComponent as SpotIllustration } from '@src/assets/icons/spot-illustration.svg';
import { ReactComponent as SquareTick } from '@src/assets/icons/square-tick.svg';
import { ReactComponent as StarInHandOutline } from '@src/assets/icons/star-in-hand-outline.svg';
import { ReactComponent as StarInHand } from '@src/assets/icons/star-in-hand.svg';
import { ReactComponent as Startek } from '@src/assets/icons/star.svg';
import { ReactComponent as StatementServiceSolid } from '@src/assets/icons/statement-service-solid.svg';
import { ReactComponent as StatementServive } from '@src/assets/icons/statement-service.svg';
import { ReactComponent as StatementIcon } from '@src/assets/icons/statementicon.svg';
import { ReactComponent as StopCircle } from '@src/assets/icons/stop-circle.svg';
import { ReactComponent as SuccessIconComponent } from '@src/assets/icons/success-icon-component.svg';
import { ReactComponent as SuccessIcon } from '@src/assets/icons/success-icon.svg';
import { ReactComponent as SuccessGreenTick } from '@src/assets/icons/success-tick.svg';
import { ReactComponent as SunIcon } from '@src/assets/icons/sun.svg';
import { ReactComponent as TaxOrange } from '@src/assets/icons/tax-orange.svg';
import { ReactComponent as TenureIcon } from '@src/assets/icons/tenure.svg';
import { ReactComponent as ThreeDotIcon } from '@src/assets/icons/three-dot.svg';
import { ReactComponent as TickCircleIcon } from '@src/assets/icons/tick-circle.svg';
import { ReactComponent as Timer } from '@src/assets/icons/timer.svg';
import { ReactComponent as Trade } from '@src/assets/icons/trade.svg';
import { ReactComponent as TransactionMinus } from '@src/assets/icons/transaction-minus.svg';
import { ReactComponent as TransactionIconOutlined } from '@src/assets/icons/transaction-outlined.svg';
import { ReactComponent as TransactionPlus } from '@src/assets/icons/transaction-plus.svg';
import { ReactComponent as TransactionSuccessIcon } from '@src/assets/icons/transaction-success.svg';
import { ReactComponent as Trash } from '@src/assets/icons/trash.svg';
import { ReactComponent as TwoTimeIcon } from '@src/assets/icons/twoTimeSettlement-icon.svg';
import { ReactComponent as UKFlagIcon } from '@src/assets/icons/uk-flag.svg';
import { ReactComponent as UnblockCard } from '@src/assets/icons/unblock-card.svg';
import { ReactComponent as Unlock } from '@src/assets/icons/unlock.svg';
import { ReactComponent as UpIcon } from '@src/assets/icons/up-icon.svg';
import { ReactComponent as UpiGroupIcon } from '@src/assets/icons/upi-group.svg';
import { ReactComponent as UpiIcon } from '@src/assets/icons/upi-icon.svg';
import { ReactComponent as UploadIcon } from '@src/assets/icons/upload.svg';
import { ReactComponent as UpsideIcon } from '@src/assets/icons/upside-icon.svg';
import { ReactComponent as UserCard } from '@src/assets/icons/user-card.svg';
import { ReactComponent as UserCheck } from '@src/assets/icons/user-check.svg';
import { ReactComponent as UserCircleAddIcon } from '@src/assets/icons/user-cirlce-add.svg';
import { ReactComponent as Userdisable } from '@src/assets/icons/user-disable.svg';
import { ReactComponent as UserFeaturedIcon } from '@src/assets/icons/user-featured.svg';
import { ReactComponent as UserOrangeIcon } from '@src/assets/icons/user-icon.svg';
import { ReactComponent as UserModal } from '@src/assets/icons/user-modal.svg';
import { ReactComponent as UserPlusOrange } from '@src/assets/icons/user-plus-orange.svg';
import { ReactComponent as UserPlusWhite } from '@src/assets/icons/user-plus-white.svg';
import { ReactComponent as UserPlus } from '@src/assets/icons/user-plus.svg';
import { ReactComponent as UserPreview } from '@src/assets/icons/user-preview.svg';
import { ReactComponent as UserSquare } from '@src/assets/icons/user-square.svg';
import { ReactComponent as UserTickIcon } from '@src/assets/icons/user-tick.svg';
import { ReactComponent as UserIcon } from '@src/assets/icons/user.svg';
import { ReactComponent as Users } from '@src/assets/icons/users.svg';
import { ReactComponent as ValidImage } from '@src/assets/icons/valid-case.svg';
import { ReactComponent as VerifiedIcon } from '@src/assets/icons/verified.svg';
import { ReactComponent as VerifyDebitCard } from '@src/assets/icons/verify-debit-card.svg';
import { ReactComponent as VerifyFaceMatch } from '@src/assets/icons/verify-face-match.svg';
import { ReactComponent as VideoBanking } from '@src/assets/icons/video-banking-profile.svg';
import { ReactComponent as VideoCamera } from '@src/assets/icons/video-camera.svg';
import { ReactComponent as Video } from '@src/assets/icons/video.svg';
import { ReactComponent as VisaCard } from '@src/assets/icons/visa-card.svg';
import { ReactComponent as VkycCamera } from '@src/assets/icons/vkyc-camera.svg';
import { ReactComponent as WalletAdd } from '@src/assets/icons/wallet-add.svg';
import { ReactComponent as WarningIconModal } from '@src/assets/icons/warning-icon.svg';
import { ReactComponent as WarningModalIcon } from '@src/assets/icons/warning-modal-icon.svg';
import { ReactComponent as WarningRefreshIcon } from '@src/assets/icons/warning-refresh-icon.svg';
import { ReactComponent as WarningIcon } from '@src/assets/icons/warning.svg';
import { ReactComponent as WhatsappQrIcon } from '@src/assets/icons/whatsapp-qrcode.svg';
import { ReactComponent as WhatsApp } from '@src/assets/icons/whatsapp.svg';
import { ReactComponent as XCircleOutlined } from '@src/assets/icons/x-circle-outlined.svg';
import { ReactComponent as XOctagon } from '@src/assets/icons/x-octagon.svg';
import { ReactComponent as XlsIcon } from '@src/assets/icons/xls.svg';
import { ReactComponent as ZapBlue } from '@src/assets/icons/zap-blue.svg';
import { ReactComponent as ZapOff } from '@src/assets/icons/zap-off.svg';
import { ReactComponent as ZapIcon } from '@src/assets/icons/zap.svg';

interface IconProps {
  icon: string;
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

export const iconMapping = {
  'upi-icon': UpiIcon,
  'info-circle-transparent': InfoCircleTransparent,
  'operator-icon': Operator,
  'user-orange-icon': UserOrangeIcon,
  'bank-orange': BankOrange,
  'bank-orange-bg': BankIconOrange,
  'bank-image': BankImage,
  'location-orange': LocationOrange,
  'tax-orange': TaxOrange,
  'delete-error': DeleteError,
  'pay-icon': PayIcon,
  'realTime-icon': RealTimeIcon,
  'oneTime-icon': OneTimeIcon,
  'twoTime-icon': TwoTimeIcon,
  'entitle-icon': Entitle,
  'refresh-red': RefreshCenterDot,
  check: CheckIcon,
  'orange-dot': OrangeDot,
  flag: FlagIcon,
  info: Info,
  'settlement-icon': SettlementIcon,
  'limit-icon': LimitIcon,
  'trans-success': TransactionSuccessIcon,
  'remove-account': RemoveAccount,
  'collection-store': Collection,
  'settlement-store': Settlement,
  'dropdown-icon-transparent': DropDownTransparent,
  language: Language,
  help: HelpIcon,
  'help-head': HelpHeadIcon,
  'scf-icon': SCFIcon,
  'error-modal': ErrorModal,
  'apply-now': ApplyNow,
  'au-logo': AuLogo,
  mail: MailIcon,
  minus: MinusIcon,
  whatsapp: WhatsApp,
  'video-banking': VideoBanking,
  upload: UploadIcon,
  verified: VerifiedIcon,
  'real-time-settlement': RealTimeSettlement,
  'user-tick': UserTickIcon,
  'scan-qr': ScanQrCode,
  'setting-circle': SettingCircle,
  moon: Moon,
  // 'change-password': ChangePassword,
  close: CloseIcon,
  'user-modal': UserModal,
  'stop-circle': StopCircle,
  'arrow-square': ArrowSquare,
  'info-circle': InfoCircleIcon,
  building: Building,
  'tick-circle': TickCircleIcon,
  'send-plain-icon': SendPlain,
  'hdfc-icon': HdfcIcon,
  'sbi-icon': SbiIcon,
  warning: WarningIcon,
  'sad-emoji': SadEmoji,
  'happy-emoji': HappyEmoji,
  warningmodal: WarningIconModal,
  activity: ActivityIcon,
  'deposit-amount': DepositAmountIcon,
  'discount-shape': InterestPayoutTypeIcon,
  tenure: TenureIcon,
  additional: AdditionalDetailIcon,
  feature: FeaturedIcon,
  logout: Logout,
  download: DownloadIcon,
  edit: Edit,
  'left-arrow': LeftArrowIcon,
  'review-detail': ReviewDetailIcon,
  'help-circle': HelpCircle,
  bell: Bell,
  eyeicon: EyeIcon,
  'chevron-up': ChevronUpIcon,
  'featured-icon': FeaturedIconSVG,
  'star-in-hand-outline': StarInHandOutline,
  featured: Featured,
  'chevron-down': ChevronDownIcon,
  'cheque-services': ChequeServices,
  'chevron-right': ChevronRight,
  'info-circleoutline': InfoCircleOutlineIcon,
  'in-flag': INFlagIcon,
  'uk-flag': UKFlagIcon,
  'document-text': DocumentText,
  bag: Bag,
  'personal-card': PersonalCard,
  email: Email,
  eyeOff: EyeOff,
  eyeOffCasa: EyeOffCasa,
  alertCircle: AlertCircleIcon,
  user: UserIcon,
  sortBy: SortBy,
  moneysack: MoneySack,
  greenuparrow: GreenUpArrow,
  maturityicon: MaturityIcon,
  copyicon: CopyIcon,
  pencilicon: PencilIcon,
  receipticon: ReceiptIcon,
  downloadicon: DownloadOrangeIcon,
  downloadicongray: DownloadGrayIcon,
  infoicon: InfoIcon,
  rightarrow: RightArrow,
  investment: Investment,
  rightup: RightUp,
  greentriangle: GreenTriangleIcon,
  threedoticon: ThreeDotIcon,
  interestwhiteicon: InterestWhiteIcon,
  back: Back,
  transactionplus: TransactionPlus,
  transactionminus: TransactionMinus,
  'qr-code': QRCode,
  'qr-code-icon': QrCodeIcon,
  'upi-group-icon': UpiGroupIcon,
  'file-text': Docx,
  'file-text-circle': DocCircle,
  usercheck: UserCheck,
  'money-remove': MoneyRemove,
  'wallet-add': WalletAdd,
  copyorange: CopyOrange,
  safetylock: SafetyLock,
  depositcard: DepositCard,
  squaretick: SquareTick,
  percentagesquare: PercentageSquare,
  starinhand: StarInHand,
  'check-square': CheckSquare,
  'user-check': UserCheck,
  lock: LockIcon,
  unlock: Unlock,
  'home-outline': HomeOutline,
  'get-statement-icon': GetStatementIcon,
  'success-icon': SuccessIcon,
  'success-icon-component': SuccessIconComponent,
  'arrow-icon': ArrowIcon,
  'chevron-left': ChevronLeft,
  'video-camera': VideoCamera,
  'chevron-right-orange': ChevronRightOrange,
  rightarrowblack: RightArrowBlackIcon,
  'message-edit': MessageEdit,
  'blank-checkbox': BlankCheckbox,
  rupees: Rupees,
  IND: UserCard,
  SOLPROP: House,
  'download-in-progress': DownloadInProgress,
  OTH: Others,
  OTHF: OthersFilled,
  'check-icon': CheckRoundIcon,
  greentick: TickGreen,
  'check-circle': CheckCircleIcon,
  arrowright: ArrowRight,
  'offer-outline': OfferOutline,
  debitcard: DebitCard,
  statementicon: StatementIcon,
  moneytransfericon: MoneyTransferIcon,
  dot: DotIcon,
  'alert-modal': AlertModalIcon,
  address: Address,
  'customer-name': Customername,
  'date-of-birth': DateofBirth,
  gender: Gender,
  'put-finger': PutFinger,
  'connection-failed': ConnectionFailed,
  'biometric-failed': BiometricFailed,
  'biometric-success': BiometricSuccess,
  'connect-device': ConnectDevice,
  'statement-solid': StatementServiceSolid,
  evolute: Evolute,
  startek: Startek,
  mobile: Mobile,
  'profile-add': ProfileAdd,
  eyeopen: EyeOpen,
  greendot: GreenDot,
  alerttriangle: AlertTriangle,
  redalerttriangle: RedAlertTriangle,
  timer: Timer,
  trade: Trade,
  alertredtriangle: AlertRedTriangle,
  'user-preview': UserPreview,
  'app-store': AppStoreIcon,
  'play-store': PlayStoreIcon,
  camera: Camera,
  loadingicon: LoadingIcon,
  document: Document,
  delete: Delete,
  'message-square': MessageSquareIcon,
  refer: ReferIcon,
  editColor: EditColor,
  visacard: VisaCard,
  rupaycard: RupayCard,
  'up-icon': UpIcon,
  trash: Trash,
  'valid-image': ValidImage,
  'invalid-image': InvalidImage,
  'other-address': OtherAddress,
  'permanent-address': PermanentAddress,
  'business-address': BusinessAddress,
  'plus-white-icon': PlusWhiteIcon,
  'share-icon': ShareIcon,
  'chevron-orange-right': ChevronOrangeRight,
  'pay-arrow-icon': PayArrowIcon,
  'help-orange-circle': HelpOrangeCircle,
  phoneicon: PhoneIcon,
  arrowRightCircle: ArrowRightCircleIcon,
  'user-plus-orange': UserPlusOrange,
  bankicon: BankIcon,
  filterIcon: FilterIcon,
  searchIcon: Search,
  maximizeIcon: MaximizeIcon,
  cross: Cross,
  minimizeIcon: MinimizeIcon,
  csvIcon: CsvIcon,
  xlsIcon: XlsIcon,
  pdfIcon: PdfIcon,
  sendWhiteOutline: SendWhiteOutline,
  plus: Plus,
  downloadCloudGray: DownloadCloudGray,
  downloadCloudWhite: DownloadCloudWhite,
  downloadCloudOrange: DownloadCloudOrange,
  downloadCloudBlue: DownloadCloudBlue,
  emailOutline: EmailOutline,
  mailOrange: MailOrange,
  mailGray: MailGray,
  search: Search,
  rework: ReworkIcon,
  calendar: CalendarIcon,
  'dropdown-icon': DropdownIcon,
  'personal-info': PersonalInfo,
  'profile-2user': Profile2User,
  'send-icon': SendIcon,
  sendOrangeIcon: SendOrangeIcon,
  'deactivate-payee': DeactivatePayee,
  'warning-modal-icon': WarningModalIcon,
  'pin-orange': PinOrange,
  'money-transfer-solid': MoneyTransferSolid,
  refreshIcon: RefreshIcon,
  'clock-icon-outlined': ClockIconOutlined,
  transactionicon: TransactionIconOutlined,
  'user-circle-add': UserCircleAddIcon,
  'zap-icon': ZapIcon,
  'money-send': MoneySendIcon,
  'sun-icon': SunIcon,
  'receipt-fill-icon': ReceiptFillIcon,
  'view-payee-dot-icon': ViewPayeeDotIcon,
  'chevron-neutral': ChevronNeutral,
  'chevron-up-arrow': ChevronUpArrow,
  greenSuccess: GreenSuccesIcon,
  repeatIcon: RepeatIcon,
  bankLogo: BankLogo,
  pendingIcon: PendingIcon,
  failedIcon: FailedIcon,
  retryIcon: RetryIcon,
  pendingInlineIcon: PendingInlineIcon,
  'date-featured-icon': DateFeaturedIcon,
  'retry-featured-icon': RetryFeaturedIcon,
  greenCircle: GreenCircleTick,
  'dot-payeelist': DotPayeelist,
  'refresh-cw': RefreshCw,
  'x-circle-outlined': XCircleOutlined,
  'arrow-up-right': ArrowUpRight,
  'arrow-right-up': ArrowUpRight,
  'check-circle-green': CheckCircleGreen,
  'no-contact': NoContact,
  'bank-logo-gray-300': BankLogoGray300,
  'plus-icon-orange': PlusIconOrange,
  'zap-blue': ZapBlue,
  'refresh-plane-icon': RefreshPlaneIcon,
  'upside-icon': UpsideIcon,
  refresh: Refresh,
  'red-trash': RedTrash,
  'bbps-autopay': BbpsAutopay,
  'rotate-blue': RotateBlue,
  'user-plus': UserPlus,
  'user-plus-white': UserPlusWhite,
  'comment-icon': CommentIcon,
  'humberger-close-icon': HumbergerCloseIcon,
  notification: NotificationIcon,
  home: HomeIcon,
  slash: Slash,
  loan: LoanIcon,
  card: CardIcon,
  payment: PaymentIcon,
  service: ServiceIcon,
  downloadStatementIcon: DownloadStatementIcon,
  'dot-gray': DotGray,
  'filter-icon-outline': FilterOutlineIcon,
  'password-check': PasswordCheck,
  'setting-3': Setting3,
  cup: Cup,
  'lock-orange': LockOrange,
  'dot-white': DotWhite,
  'chevron-right-black': ChevronRightBlack,

  'blocked-icon': BlockedIcon,
  'unblock-card': UnblockCard,
  'no-debit-card-icon': NoDebitCardIcon,
  'card-icon': DebitCardIcon,
  video: Video,
  'atm-use-icon': AtmUseIcon,
  'deposit-incoming': DepositIncoming,
  'pos-usage-icon': PosUsageIcon,
  'online-usage-icon': OnlineUsageIcon,
  'cardless-icon': CardlessIcon,
  'info-icon': InfoIcon,
  'refresh-icon-gray': RefreshIconGray,
  'warning-refresh-icon': WarningRefreshIcon,
  'outline-wallet': OutlineWallet,
  'outline-unblocked-card': OutlineUnblockedCard,
  'outline-circle-check': OutlineCircleCheck,
  'outline-warning': OutlineWarning,
  'outline-credit-card': OutlineCreditCard,
  headphones: HeadPhones,
  'external-link': ExternalLink,
  'deposit-service': DepositService,
  'card-service': CardsService,
  'statement-service': StatementServive,
  'money-transfer-service': MoneyTransferService,
  'manage-payee-service': ManagePayeeService,
  'refresh-cw-orange': RefreshCwOrange,
  'close-account': CloseAccountIcon,
  'refresh-outlined': RefreshIconOutlined,
  'home-loan': OutlineHomeLoan,
  docs: Docs,
  pause: Pause,
  'flag-triangle': FlagTriangle,
  'flag-mark': FlagMark,
  'mobile-bold': MobileBold,
  'email-bold': EmailBold,
  'sound-box': SoundBox,
  'left-arrow-outlined': LeftArrowOutlined,
  'right-arrow-outlined': RightArrowOutlined,
  'info-outlined-outlined': InfoOutlinedIcon,
  'clock-bold': ClockBold,
  'credit-card': CreditCard,
  'outline-password-check': OutlinePasswordCheck,
  'outline-setting-4': OutlineSetting4,
  'outline-lock': OutlineLock,
  'outline-toggle-on-circle': OutlineToggleOnCircle,
  'outline-cup': OutlineCup,
  'outline-card': OutlineCard,
  'outline-message-question': OutlineMessageQuestion,
  'outline-gift': OutlineGift,
  'outline-card-edit': OutlineCardEdit,
  'home-featured': HomeFeatured,
  'address-featured': AddressFeatured,
  'file-with-line-outlined': FileWithLineOutlined,
  'au-rewards': AURewardsLogo,
  'business-loan': OutlineBusinessloan,
  'personal-loan': OutlinePersonalLoan,
  'vehicle-loan': OutlineAutoLoan,
  'car-loan': OutlineAutoLoan,
  'gold-loan': OutlineGoldLoan,
  'agri-term-loan': OutlineAgriTermLoan,
  'two-wheeler-loan': OutlineTwoVehicleLoan,
  'pdf-placeholder': PlaceholderPdf,
  'shield-tick': ShieldTick,
  reply: Reply,
  'error-500': Error500,
  'check-circle-outline': CheckCircleOutline,
  'profile-2user-outline': Profile2UserOutline,
  settings: Settings,
  graph: Graph,
  'arrow-down-left': ArrowDownLeft,
  'arrow-down': ArrowDown,
  'search-status': SearchStatus,
  'search-status-transparent': SearchStatusNew,
  'approved-icon': ApprovedIcon,
  'rework-icon': ReworkIconNew,
  'package-icon': PackageIcon,
  'reports-in-progress': ReportsinProgress,
  'contact-branch': ContactBranch,
  'outlined-arrow-down-left': OutlinedArrowDownLeft,
  'grey-plus': GreyPlusIcon,
  'allowance-icon': AllowanceIcon,
  'hassle-free': HassleFreeIcon,
  'control-icon': ControlIcon,
  'flexibility-icon': FlexibilityIcon,
  'user-featured': UserFeaturedIcon,
  'pen-tool': PenToolIcon,
  funnel: Funnel,
  'outline-calendar': OutlineCalendarIcon,
  'zap-off': ZapOff,
  briefcase: BriefCase,
  locker: LockerIcon,
  'bank-outline': BankOutlineIcon,
  'saving-account': SavingAccount,
  fastag: Fastag,
  'business-loan-apply-now': BusinessLoan,
  'case-history': CaseHistoryIcon,
  'verify-debit-card': VerifyDebitCard,
  'verify-face-match': VerifyFaceMatch,
  'mobile-device': MobileDevice,
  'mac-os': MacDevice,
  'desktop-device': DesktopDevice,
  hamburger: Hamburger,
  'email-thin': EmailThinIcon,
  'call-thin': CallThinIcon,
  faq: FaqIcon,
  'icon-lamp': IconLamp,
  'face-auth': FaceAuth,
  'privacy-policy': PrivacyPolicyIcon,
  'about-us': AboutUsIcon,
  'user-disable': Userdisable,
  'auro-chat': AuroChatIcon,
  'red-attention-circle': RedAttentionCircle,
  'vkyc-camera': VkycCamera,
  'whatsapp-qr': WhatsappQrIcon,
  'password-check-fill': PasswordCheckFill,
  'pencil-dash': PencilDashIcon,
  users: Users,
  'delete-orange-icon': DeleteOrangeIcon,
  'placeholder-img': PlaceHolderImg,
  'maintenance-icon': MaintenanceIcon,
  'outline-coins': CoinIcon,
  'mail-new-icon': MailNewIcon,
  'phone-new-icon': PhoneNewIcon,
  'no-payment-icon': NoPaymentIcon,
  'x-otagon-icon': XOctagon,
  'stop-circle-gray-icon': StopCircleGray,
  'user-square': UserSquare,
  'rotate-left-arrow': RotateLeftArrow,
  'buildings-icon': BuildingsIcon,
  'building-outlined': BuildingOutlined,
  'outlined-plus-circle': OutlinedPlusCircle,
  'setting-icon': SettingIcon,
  'red-info': RedInfoIcon,
  'green-clock': GreenClock,
  'spot-illustration': SpotIllustration,
  'check-gray-icon': CheckGrayIcon,
  'success-tick': SuccessGreenTick,
  'clock-icon': ClockIcon,
  mastercard: MasterCard,
};
export const Icon: React.FC<IconProps> = ({
  icon,
  width = 24,
  height = 24,
  color = 'currentColor',
  className = '',
}: IconProps) => {
  let IconComponent;
  if (iconMapping[icon]) {
    IconComponent = iconMapping[icon];
  } else {
    IconComponent = iconMapping['placeholder-img'];
  }
  return (
    <>
      {IconComponent ? (
        <IconComponent
          width={width}
          height={height}
          color={color}
          fill={color}
          className={className}
        />
      ) : null}
    </>
  );
};
